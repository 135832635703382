<template>

    <div class="orte-section section pt-lg-0" v-if="dataSet.data !== null">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-3 col-xl-4">
                    <div class="section-head mt-lg-40">
                        <h3 class="section-head__title">Inspirationen für dich</h3>
                        <h4 class="section-head__info">Wie wäre es mit diesen Orten:</h4>
                        <a :href="linkTo" title="Mehr Veranstaltungstipps anzeigen" class="btn mt20 d-none d-lg-block">MEHR ANZEIGEN</a>
                    </div>
                </div>

                <div class="col-12 col-lg-9 col-xl-8">
                    <div class="row mobile--scrolling dropShadowWorkaround">
                        <div class="institution-card d-flex align-items-stretch" v-for="(item, index) in dataSet.data" :key="index">
                            <ort-card :item="item" :noslider="true"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

  import { getContentsOfType } from '@/api/content';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: "orteSection",
    components: {
        OrtCard: () => import('@/components/cards/Ort.vue'),
    },
    props: {
        mainCategory: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            dataSet: {
              total: 0,
              data: null,
              meta:null,
              query: {
                page:1,
                limit: 3,
                selectRandomly: true,
                selectedContentTypeId: this.$institutionId,
                view_status: 1,
                sort: {
                  prop: '',
                  order: 'asc'
              },
          },
      },
  }
},
created() {
  this.getContents();
}, 
computed:{
    linkTo(){
        const args = new Map();
        args.set(this.mainCategory.id,[this.mainCategory.value]);
        return "/orte?filters="+encodeURIComponent(JSON.stringify(Array.from(args.entries())));
    }
},
methods: {
   showLoader, 
   hideLoader,
   getContents() {

    if(this.mainCategory!==null){
        const args = new Map();
        args.set(this.mainCategory.id,this.mainCategory.value);
        this.dataSet.query.filters = JSON.stringify(Array.from(args.entries()));
    }

    this.dataSet.query.type="view";
    const { limit, page } = this.dataSet.query;
    getContentsOfType(this.$institutionId,this.dataSet.query).then(response => {
        const { data, meta } = response;
        this.dataSet.data = data;
        this.dataSet.data.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1;
      });
        this.dataSet.meta = meta;
        this.dataSet.total = meta.total;
        this.tempContentCount = meta.total;
    });
},
}
}
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .orte-section {

        .institution-card{
            width: 325px;
            min-width: 325px;
            margin-left: 15px;

            .card.no-slider {
              margin-right: 0;
              margin-left: 0px;
          }

          &:last-child{
            margin-right:15px;
        }
    }

    a.btn{
        color: #fff;
        max-width: 210px;

        &:hover{
            color: #C8D400;
            text-decoration: none;
        }
    }

}

.dropShadowWorkaround {
    padding-bottom: 10px;
}

body[dir="rtl"] {
    .orte-section {
        margin-right: 0px;

        .institution-card {


            &:first-child{
                padding-right: 15px;
            }

            &:last-child{
                margin-right: 0px;
            }
        }
    }
}

</style>
